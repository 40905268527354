@keyframes bn-fade-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes bn-fade-out {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@define-mixin .bn-fade-in {
  animation: bn-fade-in 0.4s;
  opacity: 1 !important;
}

.bn-fade-inline {
  display: inline-block !important;

  @mixin .bn-fade-in;
}

.bn-fade-block {
  display: block !important;

  @mixin .bn-fade-in;
}

.bn-fade-flex {
  display: flex !important;

  @mixin .bn-fade-in;
}
