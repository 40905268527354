.bn-cta {
  /* This class is only to be used for styling the CTA component that we provide(d) as a ckeditor extension.
  It should not be used for any other purpose. */
  background-color: white;
  border: var(--box-border);
  border-color: #dbe2e5; /* tw-grey-100 */
  border-radius: var(--box-border-radius-s);
  box-shadow: var(--box-shadow);
  margin-bottom: var(--spacing-l);
  overflow: hidden;
  padding: 32px 36px;
  position: relative;

  &__main-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  &__title {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: var(--spacing);
  }

  &__btn-wrapper {
    align-items: center;
    display: flex;
    margin-left: var(--spacing-l);
    white-space: nowrap;

    @media (--screen-md-max) {
      margin: var(--spacing) 0 0 0;
    }
  }

  p {
    margin-bottom: 0;
  }

  &__link {
    a {
      align-items: center;
      background-color: var(--bn-orange);
      border: none;
      border-radius: 9px;
      color: var(--black) !important;
      display: inline-flex;
      font-size: 14px;
      font-weight: var(--font-semi-bold);
      line-height: 1.5rem;
      padding: 16px 32px;
    }
  }

  a {
    margin-right: var(--spacing-xxs);
  }

  a:hover {
    text-decoration: none;
  }
}

.full-width-on-mobile {
  width: 100%;

  @media screen and (--screen-xs-min) {
    width: fit-content;
  }
}
