.awesomplete {
  display: block;
  flex: 1 1 auto;
  font-weight: var(--font-medium);
  position: static;
}

/* remove the diamond from the top of the dropdown */
.awesomplete > ul::before {
  display: none;
}

/* want the matched parts of text to have no background
but to be highlighted bright network orange */
.awesomplete mark {
  background: none;
  font-weight: var(--font-semi-bold);
}

.awesomplete li:hover mark {
  background: none;
  font-weight: var(--font-semi-bold);
}

.awesomplete > ul {
  background-color: white;
  border: var(--box-border);
  border-radius: 9px;
  box-shadow: var(--box-shadow);
  padding: var(--spacing-xxs);
  position: absolute;
  top: 56px;
}

#id_search_form .awesomplete > ul {
  top: 38px;
}

.awesomplete > ul > li {
  padding-left: 0.8rem;
}

.awesomplete > ul > li:hover {
  background-color: var(--tw-blue-15);
  border-radius: 4px;
  padding-left: 0.8rem;
}

/* removing padding on highlighted elements */
.mark,
mark {
  padding: 0;
}

/* matching background colour of tabbed elements with nav item blue */
.awesomplete > ul > li[aria-selected="true"] {
  background: var(--tw-blue-15);
  border-radius: 4px;
  color: var(--text-primary);
}

/* font colour of tabbed item bn-orange */
.awesomplete li[aria-selected="true"] mark {
  background: none;
  font-weight: var(--font-semi-bold);
}
