/* Typography
* This file should be used as single source of truth for all typography
*/

h1,
h2,
h3 {
  margin-bottom: var(--spacing);
}

h1,
h2 {
  font-weight: var(--font-semi-bold);
}

h1 {
  font-size: 2.25rem;

  @media (--screen-md) {
    font-size: 2rem;
  }

  @media (--screen-xs) {
    font-size: 1.5rem;
  }
}

h2 {
  font-size: 1.5rem;
}

h3 {
  font-size: 1.3rem;
  font-weight: var(--font-regular);
}

.header-suplement {
  font-size: var(--font-lg);
  text-transform: uppercase;

  &--light {
    color: var(--grey-100);
  }
}
