.bn-card-style {
  background-color: white;
  border: var(--box-border);
  border-radius: var(--box-border-radius-s);
  box-shadow: var(--box-shadow);
  width: 100%;

  a:hover {
    text-decoration: none;
  }

  &--rounded {
    border-radius: var(--box-border-radius);
    overflow: hidden;
  }

  &--rounded-s {
    border-radius: var(--box-border-radius-s);
    overflow: hidden;
  }

  &--padded {
    padding: var(--spacing-s) var(--spacing);
  }
}
