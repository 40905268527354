@define-mixin selectable {
  background-color: transparent;
  padding: var(--spacing-xxxs) var(--spacing-xxs) var(--spacing-xxxs) 1.625rem;
  position: relative;
  border-radius: var(--box-border-radius);
  font-size: var(--font-xs);
  line-height: 2.5;
  cursor: pointer;
}

@define-mixin selectable-selected {
  background-color: rgba(var(--bn-light-blue-rgb), 0.05);
}

@define-mixin selectable-before {
  content: "";
  height: 100%;
  position: absolute;
  margin: 0 auto;
  width: 2rem;
  left: 0;
  background-position: center;
  top: 0;
}

@define-mixin selectable-selected-before {
  background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M2 8l4.5 5L16 2.5' stroke='%2376A8F4' stroke-width='4' fill='none' fill-rule='evenodd' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-size: 0.75rem;
  background-position: calc(50% - 1px) calc(50% + 0.125rem);
}

.pill-selectable {
  --animation-duration: 0.4s;
  display: inline;
  @mixin selectable;

  &:before {
    @mixin selectable-before;
  }

  &--selected {
    @mixin selectable-selected;
    &:before {
      @mixin selectable-selected-before;
    }
    @media (hover) {
      &-hover:hover {
        background-color: rgba(var(--bn-danger-rgb), 0.05);
        &:before {
          background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 18 19' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.6531 13.1641L5.71057 5.83589M14.9672 15.6068C18.1625 12.234 18.1625 6.76596 14.9672 3.39316C11.7719 0.0203591 6.59169 0.0203591 3.3964 3.39316C0.201113 6.76596 0.201113 12.234 3.3964 15.6068C6.59169 18.9796 11.7719 18.9796 14.9672 15.6068ZM5.71057 13.1641L12.6531 5.83589L5.71057 13.1641Z' stroke='%23D94161' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
          background-repeat: no-repeat;
          background-size: 1rem;
          background-position: calc(50% - 0.125rem) center;
          animation: animate ease-out var(--animation-duration);
        }
      }
    }
  }

  &--deselected {
    @media (hover) {
      &:hover {
        background-color: rgba(var(--bn-pistachio-rgb), 0.05);
        &:before {
          background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 18 19' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M9 14.6818V4.31818M9 18.1364C13.5188 18.1364 17.1818 14.2699 17.1818 9.5C17.1818 4.73014 13.5188 0.863636 9 0.863636C4.48118 0.863636 0.81818 4.73014 0.81818 9.5C0.81818 14.2699 4.48118 18.1364 9 18.1364ZM4.09091 9.5H13.9091H4.09091Z' stroke='%2365CCB5' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
          background-repeat: no-repeat;
          background-size: 1rem;
          background-position: calc(50% - 0.125rem) center;
          animation: animate ease-out var(--animation-duration);
        }
      }
    }
  }
}

a.pill-selectable.pill-selectable--selected,
a.pill-selectable.pill-selectable--selected:hover {
  color: var(--bn-light-blue);
}
a.pill-selectable.pill-selectable--selected-hover:hover {
  color: var(--bn-danger-bg);
}

a.pill-selectable.pill-selectable--deselected:hover {
  color: var(--bn-success-bg);
}

a.pill-selectable.pill-selectable--selected,
a.pill-selectable.pill-selectable--selected:hover,
a.pill-selectable.pill-selectable--deselected:hover {
  font-weight: var(--font-semi-bold);
}

/* mobile ui */

@media (--screen-md) {
  .mobile-active a.pill-selectable {
    @mixin selectable;
    @mixin selectable-selected;
    color: var(--bn-light-blue);
    font-weight: var(--font-semi-bold);

    &:before {
      @mixin selectable-before;
      @mixin selectable-selected-before;
    }

    &:hover,
    &:active {
      color: var(--bn-light-blue);
      font-weight: var(--font-semi-bold);
    }
  }

  .mobile-inactive a.pill-selectable {
    @mixin selectable;
    color: var(--bn-text);
    font-weight: var(--font-regular);

    &:before {
      background: none;
    }

    &:hover,
    &:active {
      color: var(--bn-text);
      font-weight: var(--font-regular);
    }
  }
}

@keyframes animate {
  0% {
    opacity: 0;
    transform: translateX(0.5rem);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}
