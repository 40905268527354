@keyframes cookieSlideIn {
  from {
    transform: translateY(100vh);
  }

  to {
    transform: translateY(0);
  }
}

.bn-cookie-prompt {
  animation: cookieSlideIn 1000ms ease-in-out;
  background-color: white;
  bottom: 0;
  max-height: 95%;
  overflow-y: scroll;
  position: fixed;
  width: 100%;
  z-index: 10000000;

  &__wrapper {
    padding-top: var(--spacing-xl);
    position: relative;

    @media (--screen-xs-max) {
      font-size: 0.95rem;
      padding-top: var(--spacing-m);

      h2 {
        font-size: 1.2rem;
      }
    }
  }

  &__row {
    display: flex;
    flex-direction: row;
    margin-bottom: var(--spacing-l);

    @media (--screen-md-max) {
      flex-direction: column;
    }
  }

  &__column {
    flex: 1;
  }

  &__footer {
    flex-direction: column;

    h6 {
      margin-bottom: var(--spacing);

      @media (--screen-xs) {
        text-align: center;
      }
    }

    form {
      display: flex;
      flex-direction: row;
    }

    @media (--screen-xs-max) {
      form {
        flex-direction: column;
      }
    }
  }

  &__container {
    background: rgba(0, 0, 0, 80%);
    bottom: 0;
    display: none;
    height: 100vh;
    left: 0;
    opacity: 0;
    overflow: auto;
    pointer-events: none;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 9999999;

    &.show {
      display: block;
      opacity: 1;
      pointer-events: auto;
    }
  }

  @media (--screen-md-min) {
    min-height: 402px;

    &__column:first-of-type {
      margin-right: var(--spacing-xl);
    }
  }
}
