button,
input,
optgroup,
select,
textarea {
  &:invalid:focus {
    border-color: #ff0c24;
    box-shadow: 0 0 0.2rem #ff0c24;
  }
}

.modal-content {
  border: none;
  border-radius: 0;
}

.modal-header {
  border-bottom: 0;
  padding-left: 40px;
  padding-right: 40px;

  .btn-close {
    position: absolute;
    right: 1.2rem;
    top: 1.2rem;
  }

  @media (--screen-md-max) {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.modal-footer {
  border-top: 0;
}

.select {
  cursor: pointer;
}

.form-check {
  input,
  label {
    cursor: pointer;
  }

  #div_id_sector_interests & {
    margin-bottom: 7px;
  }
}
