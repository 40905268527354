.bn-collapse {
  display: block;
  max-height: 0;
  overflow: hidden;
}

.transitioning {
  transition: all 0.3s ease-in-out;
}

.bn-collapse.show {
  max-height: 100vh;
}

#cookie-table__optional.bn-collapse.show,
#cookie-table__essential.bn-collapse.show {
  overflow: scroll;
}

/* max height is needed for the collapse expand calculations, but the limit has to be high enough to not cause content overflow, which will be hidden */
#employers-facet-container.bn-collapse.show,
#career_path_sectors-facet-container.bn-collapse.show {
  max-height: fit-content;
}
