/* Deprecated: Use bk-btn */

@define-mixin button-base {
  align-items: center;
  cursor: pointer;
  display: inline-flex;
  font-size: var(--font-sm);
  font-weight: var(--font-bold);
  justify-content: center;
  line-height: 16px;
  max-height: 54px;
  padding: var(--spacing) var(--spacing-l);
  text-align: center;
}

@define-mixin button $intent {
  .bn-btn {
    &--primary-$(intent) {
      background-color: var(--bn-$(intent)-bg);
      border: 1px solid rgba(0, 0, 0, 4%);
      border-radius: var(--box-border-radius-s);
      color: var(--bn-$(intent)-color);

      &:disabled {
        background-color: var(--bn-$(intent)-bg-disabled);
        color: var(--bn-$(intent)-color-disabled);
        pointer-events: none;
      }

      &:active {
        background-color: var(--bn-$(intent)-bg-active);
      }

      @mixin button-base;

      @media (pointer: fine) {
        &:hover {
          background-color: var(--bn-$(intent)-bg-hover);
        }
      }
    }
    &--secondary-$(intent) {
      background-color: transparent;
      border: 1px solid transparent;
      color: var(--bn-$(intent)-color-secondary);

      &.disabled,
      &:disabled {
        color: var(--bn-secondary-color-disabled);
        pointer-events: none;
      }

      &:active {
        color: var(--bn-$(intent)-color-active);
      }

      @mixin button-base;
    }

    &--outline {
      background-color: transparent;
      border: 1px solid var(--grey-600);
      border-radius: var(--box-border-radius-s);
      color: var(--grey-600);

      &:active {
        color: black;
      }

      &.disabled,
      &:disabled {
        color: var(--bn-secondary-color-disabled);
        pointer-events: none;
      }

      @mixin button-base;

      @media (pointer: fine) {
        &:hover {
          border-color: black;
          color: black;
        }
      }
    }

    &--inline {
      padding-right: 0;
    }

    &__icon {
      height: 1rem;
      margin-right: var(--spacing-xxs);
      width: 1rem;

      &--light {
        filter: var(--bn-white-filter);
      }

      &--text {
        filter: var(--bn-text-filter);
      }
    }

    &--large {
      font-size: var(--font-lg);
      padding: var(--spacing) var(--spacing-xl);
    }

    &--small {
      font-size: var(--font-sm);
      padding: var(--spacing-s) var(--spacing);
    }
  }
}

@mixin button default;
@mixin button success;
@mixin button danger;
