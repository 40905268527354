/* thanks Supun https://blog.logrocket.com/creating-beautiful-tooltips-with-only-css/ */
.bn-tooltip {
  cursor: crosshair;
  position: relative; /* making the .tooltip span a container for the tooltip text */

  &::before {
    /* bn-styles */
    background: var(--grey-800);

    /* the arrow */
    border: 10px solid var(--grey-800);
    border-color: transparent var(--grey-800) transparent transparent;
    border-radius: var(--box-border-radius-s);
    color: var(--grey-600);
    content: attr(data-text); /* here's the magic */
    display: none;
    font-size: var(--font-sm);
    font-weight: var(--font-regular);

    /* move to right */
    left: 100%;
    margin-left: 15px; /* and add a small left margin */
    padding: var(--spacing--xs);
    position: absolute;
    text-align: center;
    text-transform: initial;

    /* vertically center */
    top: 50%;
    transform: translateY(-50%);

    /* basic styles */
    width: 200px;
    z-index: 100;
  }

  &:hover::before {
    display: block;
  }

  &:hover::after {
    opacity: 1;
  }

  &::after {
    /* the arrow */
    border: 10px solid var(--grey-800);
    border-color: transparent var(--grey-800) transparent transparent;
    content: "";

    /* position tooltip correctly */
    left: 100%;
    margin-left: -5px;
    opacity: 0;
    position: absolute;

    /* vertically center */
    top: 50%;
    transform: translateY(-50%);
  }
}
