.cc-invisible {
  display: none;
}

.cc-window.cc-type-info {
  opacity: 0.9;
}

.cc-window {
  .cc-message {
    color: var(--bn-light-grey-homepage);
  }
}
