.bn-bullet-point {
  align-items: center;
  display: flex;
  justify-content: center;

  .bn-bullet {
    margin-right: var(--spacing-l);
  }

  &--vertical {
    flex-direction: column;

    .bn-bullet {
      margin-bottom: var(--spacing);
      margin-right: 0;
    }

    .bn-bullet-point__content {
      text-align: center;
    }
  }

  &--horizontal {
    align-items: flex-start;
    flex-direction: row;
  }

  &__content {
    flex: 1;
    line-height: var(--line-height-03);
  }

  &__title {
    font-size: var(--font-lg);
    font-weight: var(--font-bold);
    margin-bottom: var(--spacing);
    position: relative;
    text-transform: capitalize;
  }

  &__description {
    font-weight: var(--font-light);
    margin-top: var(--spacing);
  }
}
