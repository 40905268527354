/*
Namespace: “bn-popup"
Description: "A popup used for prompting the user to take action. Contains a message among other utilities."
*/

.bn-popup {
  animation: 0.4s ease-out 1s 1 slideInFromBottom;
  animation-fill-mode: both;
  background-color: white;
  border: var(--box-border);
  border-radius: var(--box-border-radius);
  bottom: 10px;
  box-shadow: var(--box-shadow);
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: fixed;
  right: 10px;
  width: 510px;
  z-index: 999999;

  &__top {
    align-items: flex-start;
    background-color: var(--grey-100);
    display: flex;
    padding: var(--spacing) var(--spacing-l);
  }

  &__wrapper {
    padding: var(--spacing-l);

    .bn-popup__header,
    p {
      margin-bottom: 0.875rem;
    }
  }

  &__header {
    font-weight: var(--font-bold);
  }

  &__footer {
    margin-top: var(--spacing-l);

    button:last-of-type {
      margin-left: var(--spacing-xxs);
    }

    @media screen and (max-width: 576px) {
      align-items: center;
      display: flex;
      flex-direction: column;

      button {
        margin-bottom: var(--spacing);
        width: 100%;
      }
    }
  }

  &__close {
    display: none;
  }

  &__cross {
    position: absolute;
    right: var(--spacing);
    top: var(--spacing);
  }

  @media screen and (max-width: 816px) {
    right: 2.5vw;
    width: 95vw;
  }
}
