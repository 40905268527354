label.form-label {
  font-size: 14px;
  font-weight: 500;
}

.select,
.form-control {
  border-color: #c6cdd0 !important;
}

.ts-wrapper {
  border-color: var(--tw-grey-100) !important;

  .item {
    border: 0 solid #dee2e6;
    border-radius: calc(0.375rem - 1px);
    color: #343a40;
  }

  .tomselect-results {
    color: #6c757d;
    margin-top: 8px;
    padding: 0.375rem 0.75rem;

    .option {
      color: black;
    }
  }

  &.single {
    .item {
      border: none;
    }
  }

  .ts-dropdown {
    .option {
      border-radius: 4px;
      font-weight: var(--font-medium);

      .highlight {
        background-color: inherit;
        font-weight: var(--font-semi-bold);
      }

      &.selected {
        background-color: var(--tw-blue-15) !important;
      }

      &.active {
        background-color: var(--tw-blue-15);
      }

      &.selected,
      &.active {
        .highlight {
          color: inherit;
        }
      }

      &:hover {
        background-color: var(--tw-blue-15);

        .highlight {
          color: inherit;
        }
      }
    }
  }

  & + .invalid-feedback {
    display: block !important;
  }
}

#cities-section {
  .ts-wrapper {
    display: flex;
    justify-content: flex-start;

    .ts-dropdown {
      margin-left: 16px;
      margin-top: -6px;
      max-width: 316px;
      padding: 3px 8px;

      .option {
        border-radius: 4px !important;
        font-size: var(--font-sm) !important;
        font-weight: 500;

        .highlight {
          background: none !important;
          color: var(--bn-text);
          font-weight: var(--font-semi-bold);
        }
      }
    }

    .ts-control {
      background: white 0 0 no-repeat padding-box;
      border: var(--box-border);
      border-radius: var(--box-border-radius-s);
      box-shadow: none;
      font-size: var(--font-xs);
      margin: var(--spacing-xxs) var(--spacing) var(--spacing-xs);
      padding: 0.313rem 0.313rem 0.313rem 1.5rem;
      position: relative;

      &::before {
        background-image: url("data:image/svg+xml,%3Csvg width='22' height='22' viewBox='0 0 22 22' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5 11H17M11 17V5' stroke='%23949494' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
        background-position: center;
        background-repeat: no-repeat;
        background-size: 1rem;
        content: "";
        height: 1rem;
        left: 0.375rem;
        position: absolute;
        width: 1rem;
      }

      & ::placeholder {
        color: var(--bn-text);
        font-size: var(--font-xs);
      }

      .input {
        font-size: var(--font-sm) !important;
        overflow: hidden !important;
        padding: 2px !important;
        text-overflow: clip !important;
      }
    }
  }

  .focus .ts-control {
    border-color: var(--border-grey-2) !important;
  }

  .has-items .ts-control > input:focus {
    caret-color: transparent !important;
  }
}
