.bn-beta-banner {
  background-color: var(--white);
  border-radius: var(--box-border-radius-s);
  display: flex;
  font-size: var(--font-xs);
  justify-content: center;
  margin: auto 0;
  padding: var(--spacing-xs) var(--spacing-m);

  a {
    color: var(--bn-text);
    font-weight: var(--font-semi-bold);
    text-decoration: underline;
  }

  @media (--screen-lg-max) {
    border: 1px solid #ebf2fe;
  }
}
