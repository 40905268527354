.bn-list {
  list-style: none;
  padding-left: 0;

  &__item {
    border-bottom: 1px solid var(--border-grey-1);
    padding: var(--spacing-s) var(--spacing-xs);

    &--flush {
      padding: var(--spacing-s) 0;
    }

    &:last-of-type {
      border-bottom: none;
    }
  }
}
