/*
Namespace: “bn-profile"
Description: "A UI snippet to show image and author"
Usage: "Wrap content in <div class=`bn-profile`> and add class=`bn-profile__image` and `bn-profile__name` as child elements"
--vertical: adds vertical layout
--large: makes profile name large
*/

.bn-profile {
  align-items: center;
  display: flex;

  &--vertical {
    flex-direction: column;

    .bn-profile__image {
      margin: 0 0 var(--spacing-xxs) 0;

      @media screen and (max-width: 576px) {
        margin: 0 var(--spacing-xxs) 0 0;
      }
    }

    @media screen and (max-width: 576px) {
      flex-direction: row;
    }
  }

  &--large {
    .bn-profile__name {
      font-size: large;
      margin-left: var(--spacing-xxs);
    }
  }

  &__image {
    background-color: #efefef;
    border-radius: 50%;
    height: 70px;
    margin-right: var(--spacing-xxs);
    overflow: hidden;
    width: 70px;

    img {
      height: inherit;
      object-fit: cover;
      width: inherit;
    }
  }

  &__name {
    font-size: smaller;
  }
}
