/*
Namespace: “bn-tile"
Description: "A tile element best for use with content that needs additional emphasis; preferably to be used in column layout"
Usage: "wrap content in <div class=`bn-tile`> and add <h5 class=`bn-tile__header`> to your header and <img class=`bn-tile__icon`> to an icon element, where both are children of `bn-tile`"
*/

.bn-tile {
  background-color: var(--grey-100);
  padding: var(--spacing) var(--spacing-l);
  text-align: center;
  transition: all 0.3s ease-in-out;

  &:hover {
    transform: scale(1.06);
  }

  &__header {
    font-size: 1.5rem;
    font-weight: bolder;
    margin-bottom: var(--spacing-l);
  }

  &__icon {
    height: auto;
    margin: var(--spacing-l) var(--spacing) var(--spacing);
    width: 40px;
  }
}
