@keyframes slideInFromBottom {
  from {
    opacity: 0;
    transform: translateY(10vh);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slideInFromRight {
  from {
    /* opacity: 0; */
    transform: translateX(-100vw);
  }

  to {
    /* opacity: 1; */
    transform: translateX(0);
  }
}

@keyframes slideInFromLeft {
  from {
    /* opacity: 0; */
    transform: translateX(100vw);
  }

  to {
    /* opacity: 1; */
    transform: translateX(0);
  }
}

@keyframes slideInAndScale {
  from {
    opacity: 0;
    transform: scale(0.9) translateY(2vh);
  }

  to {
    opacity: 1;
    transform: scale(1) translateY(0);
  }
}
