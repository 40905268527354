.bn-cp-content-card {
  background-color: white;
  border: var(--box-border);
  border-radius: var(--box-border-radius-s);
  box-shadow: var(--box-shadow);
  font-size: var(--font-sm);
  margin-bottom: var(--spacing-l);
  overflow: hidden;
  padding: var(--spacing-l);
  position: relative;

  &::before {
    background-color: var(--bn-orange);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 8px;
  }

  &--light-blue {
    &::before {
      background-color: var(--bn-light-blue);
    }
  }

  &--mustard {
    &::before {
      background-color: var(--bn-mustard);
    }
  }

  &--pistachio {
    &::before {
      background-color: var(--bn-pistachio);
    }
  }

  &--athens-gray {
    &::before {
      background-color: var(--bn-athens-gray);
    }
  }

  &--raspberry {
    &::before {
      background-color: var(--bn-raspberry);
    }
  }

  &--light-purple {
    &::before {
      background-color: var(--bn-light-purple);
    }
  }

  &__title {
    font-weight: var(--font-semi-bold);
    margin-bottom: var(--spacing);
  }

  &__subtitle {
    color: var(--grey-400);
    font-weight: var(--font-medium);
    margin-bottom: var(--spacing-xxs);
  }
}
