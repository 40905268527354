.completion {
  margin-bottom: 1rem;

  &__wrapper {
    grid-column: 2;

    .bn-progress-bar {
      background-color: var(--grey-800) !important;

      &.intermediate::-moz-progress-bar {
        background-color: var(--bn-raspberry);
      }

      &.intermediate::-webkit-progress-value {
        background-color: var(--bn-raspberry);
      }

      &.advanced::-moz-progress-bar {
        background-color: var(--bn-light-blue);
      }

      &.advanced::-webkit-progress-value {
        background-color: var(--bn-light-blue);
      }

      &.professional::-moz-progress-bar {
        background-color: var(--bn-dark-green);
      }

      &.professional::-webkit-progress-value {
        background-color: var(--bn-dark-green);
      }

      &.bright-star::-webkit-progress-value {
        background-color: var(--bn-mustard);
      }

      &.bright-star::-moz-progress-bar {
        background-color: var(--bn-mustard);
      }
    }

    @media (--screen-xs) {
      grid-column: 1;
    }
  }

  &__title {
    align-items: baseline;
    display: flex;
    font-size: larger;
    font-weight: var(--font-bold);
  }

  &__strength {
    font-size: small;
    margin-bottom: 0.4rem;
    text-transform: capitalize;
  }

  &__prompt {
    animation: 0.4s ease-out 1s 1 slideInFromBottom;
    animation-fill-mode: both;
    background-color: white;
    border: var(--box-border);
    border-radius: var(--box-border-radius);
    bottom: 10px;
    box-shadow: -8px 0 13px rgba(0, 0, 0, 5%);
    display: none;
    max-width: 400px;
    overflow: hidden;
    position: fixed;
    right: 10px;
    z-index: 100;

    &.show {
      display: block;
    }

    .close {
      position: absolute;
      right: 20px;
      top: 18px;
      z-index: 101;
    }

    .section {
      padding: var(--spacing-l);

      .wrapper {
        align-items: center;
        display: grid;
        grid-template-columns: auto 1fr;

        img {
          grid-column: 1;
          height: auto;
          margin-right: 1rem;
          width: 5rem;

          @media (--screen-xs) {
            display: none;
          }
        }

        @media (--screen-xs) {
          grid-template-columns: 1fr;
          width: 100%;
        }
      }
    }

    > div:first-of-type {
      animation: 0.4s ease-out 1s 1 slideInAndScale;
      animation-fill-mode: both;
      background-color: var(--grey-100);
    }

    .cta {
      display: flex;
      justify-content: space-around;
      margin-top: 1.4rem;

      .bk-btn {
        white-space: nowrap;
      }
    }

    @media (--screen-xs) {
      margin: 0 auto;
      width: 95vw;
      z-index: 999999;
    }
  }
}
