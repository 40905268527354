.bn-quote {
  background-color: white;
  border: var(--box-border);
  justify-content: space-between;
  padding: var(--spacing-xl) 2rem var(--spacing-l) 5rem;
  position: relative;
  width: calc(1200px / 3 - 2 * (var(--spacing-l)));

  &__item {
    background-color: white;
    border: var(--box-border);
    box-shadow: var(--box-shadow);
    width: 100%;
  }

  &::before {
    background: url("/static/img/bn-event-page/icon/quotes.svg") no-repeat
      center / contain;
    content: "";
    height: 38px;
    left: 20px;
    position: absolute;
    top: 25px;
    width: 38px;
  }

  @media (--screen-md) {
    width: 100%;
  }
}
