.bn-bullet {
  align-items: center;
  border-radius: 50%;
  display: flex;
  font-size: 2rem;
  font-weight: var(--font-regular);
  height: 7.375rem;
  justify-content: center;
  width: 7.375rem;

  img {
    height: 3rem;
    object-fit: contain;
    width: 3rem;
  }

  &--small {
    height: 4rem;
    width: 4rem;

    img {
      height: 2rem;
      width: 2rem;
    }
  }

  &--medium {
    height: 5rem;
    width: 5rem;

    img {
      height: 2rem;
      width: 2rem;
    }
  }

  &--orange {
    background-color: var(--bn-orange-opc);
    border: 3px solid var(--bn-orange);
    color: var(--bn-orange);
  }

  &--light-blue {
    background-color: var(--bn-light-blue-opc);
    border: 3px solid var(--bn-light-blue);
    color: var(--bn-light-blue);
  }

  &--athens-gray {
    background-color: var(--bn-athens-gray-opc);
    border: 3px solid var(--bn-athens-gray);
    color: var(--bn-athens-gray);
  }

  &--light-purple {
    background-color: var(--bn-light-purple-opc);
    border: 3px solid var(--bn-light-purple);
    color: var(--bn-light-purple);
  }

  &--pistachio {
    background-color: var(--bn-pistachio-opc);
    border: 3px solid var(--bn-pistachio);
    color: var(--bn-pistachio);
  }

  &--mustard {
    background-color: var(--bn-mustard-opc);
    border: 3px solid var(--bn-mustard);
    color: var(--bn-mustard);
  }

  &--hot-red {
    background-color: var(--bn-hot-red-opc);
    border: 3px solid var(--bn-hot-red);
    color: var(--bn-hot-red);
  }

  &--raspberry {
    background-color: var(--bn-raspberry-opc);
    border: 3px solid var(--bn-raspberry);
    color: var(--bn-raspberry);
  }

  @media (--screen-sm-max) {
    height: 4rem;
    width: 4rem;

    img {
      height: 2rem;
      width: 2rem;
    }
  }
}
