.tippy-box[data-theme~="bn-custom"] {
  background: var(--white);
  border: 1px solid var(--tw-grey-75);
  border-radius: var(--box-border-radius-s);
  box-shadow: var(--box-shadow-light);
  color: var(--grey-600);
  font-size: var(--font-sm);
  font-weight: var(--font-medium);
  padding: 6px;
}

.tippy-box[data-theme~="bn-custom"][data-placement^="bottom"]
  > .tippy-arrow::before {
  border-bottom-color: var(--white);
}

.tippy-box[data-theme~="bn-custom"][data-placement^="top"]
  > .tippy-arrow::before {
  border-top-color: var(--white);
}
