.bn-notification-dot {
  border-radius: 4rem;
  font-size: var(--font-sm);
  font-weight: var(--font-semi-bold);
  padding: 0 var(--spacing-xxxs);
  text-align: center;

  &--light {
    background-color: white;
    color: var(--bn-orange);
  }
}
