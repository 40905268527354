.bn-grid {
  display: grid;
  grid-gap: var(--spacing-l);
  grid-template-columns: repeat(1, 1fr);
  justify-content: center;

  &--two {
    grid-template-columns: repeat(2, 1fr);

    @media screen and (--screen-sm) {
      grid-template-columns: repeat(1, 1fr);
    }
  }

  &--three {
    grid-template-columns: repeat(3, 1fr);

    @media screen and (--screen-md) {
      grid-template-columns: repeat(1, 1fr);
    }
  }
}

.bn-flex {
  display: flex;
  flex-wrap: wrap;
  gap: var(--spacing-l);
  justify-content: center;
}
