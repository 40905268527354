.bn-alert {
  background-color: white;
  border-radius: var(--box-border-radius-s);
  box-shadow:
    0 0 1px rgba(0, 0, 0, 20%),
    0 0 12px rgba(0, 0, 0, 10%);
  color: var(--bn-text);
  font-weight: var(--font-medium);
  margin-bottom: var(--spacing-m);
  overflow: hidden;
  position: relative;
  transition: all 0.3s ease-in-out;

  &--info {
    border-color: rgba(var(--bn-light-blue-rgb), 0.2);

    &::before {
      background-color: var(--bn-light-blue);
    }
  }

  &--success {
    border-color: rgba(var(--bn-success-rgb), 0.2);

    &::before {
      background-color: var(--bn-success-bg);
    }
  }

  &--warning {
    border-color: rgba(var(--bn-warning-rgb), 0.2);

    &::before {
      background-color: var(--bn-warning-bg);
    }
  }

  &--danger {
    border-color: rgba(var(--bn-danger-rgb), 0.2);

    &::before {
      background-color: var(--bn-danger-bg);
    }
  }

  &__header {
    font-size: var(--font-lg);
  }

  &__header,
  &__body {
    margin-bottom: var(--spacing-xs);
  }

  &__body,
  &__footer {
    font-size: var(--font-sm);
  }

  &__footer {
    align-items: center;
    display: flex;
    justify-content: space-between;

    &--bordered {
      margin-bottom: -0.375rem;
      padding: calc(var(--spacing) * 2) 0 0;
      position: relative;

      &::before {
        border-top: var(--box-border);
        content: "";
        left: calc(var(--alert-horizontal-padding) * -1);
        margin-top: 0.375rem;
        position: absolute;
        top: 0;
        width: calc(100% + var(--alert-horizontal-padding) * 2);
        z-index: 50;
      }
    }
  }

  &__cta {
    align-self: flex-end;
  }

  @media (--screen-md-max) {
    --alert-horizontal-padding: var(--spacing-m);
  }
}
