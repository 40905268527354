.bn-page-header {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: var(--spacing-l);

  &__header-wrapper {
    display: flex;
    flex-direction: row;
  }

  &__section-title {
    letter-spacing: 0.05rem;
    margin-bottom: var(--spacing);
    text-transform: uppercase;
  }

  @media (--screen-md-max) {
    margin-bottom: var(--spacing);
  }
}

.bn-page-banner {
  height: 30vh;
  margin-bottom: var(--spacing-xl);
  min-height: 300px;
  position: relative;

  &--behind {
    margin-bottom: -2rem;

    @media (--screen-sm-min) {
      margin-bottom: -1rem;
    }
  }

  &--slim {
    height: 18vh;
    margin-top: var(--spacing-xxs);
    position: relative;

    .container {
      display: flex;
      flex-direction: column;
      height: 100%;
      justify-content: flex-start;

      @media (--screen-md-min) {
        justify-content: center;
      }
    }

    @media (--screen-md-max) {
      height: 15vh;
    }
  }

  &--underline {
    border-bottom: 1px solid var(--border-grey-6);
  }

  &--ultra-slim {
    height: 12vh;
  }

  .container {
    align-items: flex-end;
    display: flex;
    height: 100%;
  }

  &::before {
    background-color: var(--grey-100);
    box-sizing: content-box;
    content: "";
    height: inherit;
    left: 0;
    min-height: inherit;
    position: absolute;
    top: 0;
    width: 100vw;
    z-index: -1;
  }

  &__img {
    background-position: center right -18px;
    background-repeat: no-repeat;
    background-size: cover;

    &--quiz {
      background-image: url("/static/img/career-path/discover-journey-maze.svg");

      @media (--screen-md-max) {
        background-position: center right -150px;
      }
    }

    &--pathway {
      background-image: url("/static/img/career-path/pathway.svg");
      background-size: contain;

      @media (--screen-md-max) {
        background-position: bottom -16px right -16px;
      }
    }

    &--pushed-left {
      @media (--screen-xs-min) {
        background-position: center right 21px;
      }
    }

    &--academy {
      background-image: url("/static/img/academy/academy-banner.svg");
      background-position: bottom 1rem right;
      background-size: 50%;

      @media (--screen-md-max) {
        background-position: top 1rem center;
      }

      @media (--screen-sm-max) {
        background-position: top 5rem center;
        background-size: 60%;
      }
    }

    &--salary-calculator {
      background-image: url("/static/img/salary-calculator/calculator-props.svg");
      background-position: center right 30px;
      background-size: 25%;

      @media (--screen-md-max) {
        background-position: top 1rem center;
        background-size: 22%;
      }

      @media (--screen-sm-max) {
        background-position: top 3rem center;
        background-size: 33%;
      }

      @media (--screen-xs-max) {
        background-position: top 3rem center;
        background-size: 33%;
      }
    }

    &--got-a-job {
      background-image: url("/static/img/job-page/discover-journey-ladder.svg");
      background-position: center right 30px;
      background-size: 25%;

      @media (--screen-md-max) {
        background-position: top 50px center;
        background-size: 20%;
      }

      @media (--screen-sm-max) {
        background-position: top 50px center;
        background-size: 30%;
      }
    }

    &--results {
      background-image: url("/static/img/career-path/career-path-sign-walking-girl.svg");
      background-position: center right 50px;
      background-size: 25%;

      @media (--screen-lg-max) {
        background-position: top 70px right 50px;
        background-size: 30%;
      }

      @media (--screen-lg-max) and (orientation: landscape) {
        background-position: top 70px right 50px;
        background-size: 25%;
      }

      @media (--screen-md-max) {
        background-position: top 40px center;
        background-size: 25%;
      }

      @media (--screen-md-max) and (orientation: landscape) {
        background-position: top 50px center;
        background-size: 20%;
      }

      @media (--screen-xs-max) {
        background-position: top 50px center;
        background-size: 35%;
      }
    }
  }

  &__title {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    margin: 0 var(--spacing) var(--spacing) 0;
    max-width: 70%;

    &--slim {
      margin-top: var(--spacing-xs);

      h1 {
        margin-bottom: 1rem;

        @media (--screen-sm-max) {
          font-size: var(--font-xlg);
        }
      }

      @media (--screen-md-min) {
        margin-top: 0;
      }
    }

    &--ultra-slim {
      h1 {
        margin-bottom: 0;
      }
    }

    a {
      margin-left: var(--spacing-xxs);
    }

    @media (--screen-md-max) {
      max-width: 100%;
    }
  }

  a {
    color: var(--bn-orange);
  }

  @media (--screen-md-max) {
    flex-direction: column;
    margin-bottom: var(--spacing-l);

    &__illustration {
      order: 1;
    }

    &__title {
      margin-top: var(--spacing);
      order: 2;
    }
  }

  @media (--screen-sm-max) {
    min-height: 400px;
  }
}

.bn-section-title {
  display: flex;
  margin: var(--spacing-l) 0;

  a {
    color: var(--bn-orange);
  }

  @media (--screen-xs-max) {
    padding: 0;
  }
}
