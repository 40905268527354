.bn-bullet-card {
  background-color: white;
  border: var(--box-border);
  border-radius: var(--box-border-radius-s);
  box-shadow: var(--box-shadow);
  margin-left: 1.5rem;
  padding: var(--spacing-l) var(--spacing-xl);
  position: relative;

  &--centred {
    align-items: center;
    display: flex;
  }

  &__bullet {
    left: 0;
    position: absolute;
    top: 48%;
    transform: translate(-50%, -50%);
  }

  &__description {
    font-size: var(--font-sm);
  }

  &--pistachio {
    background-color: var(--bn-pistachio-opc);
    border: 3px solid var(--bn-pistachio);
  }

  @media (--screen-sm) {
    margin-left: 0;
    margin-top: 1.5rem;
    padding: var(--spacing-xl) var(--spacing-l);

    &__bullet {
      left: 50%;
      top: 0;
    }
  }
}
