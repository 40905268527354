@import "./awesomplete-overrides";

:root {
  --menu-animation-duration: 0.4s;
}

@define-mixin new-label {
  background: var(--ieuk-blue);
  border: none;
  border-radius: 4px;
  color: white;
  content: attr(data-value);
  display: inline-block;
  font-size: 8px;
  font-weight: var(--font-bold);
  padding: 2px 3px;
  position: absolute;
  visibility: visible;
}

.new-label {
  @mixin new-label;
}

.no-styling {
  all: unset;
}

.mobile-menu {
  left: 0;
  list-style: none;
  padding: 40px 15px 15px 40px;
  position: absolute;
  top: 0;
  transform: translateX(0) translateZ(0);
  transition: transform var(--menu-animation-duration);
  width: 100%;
  will-change: transform;

  &__wrapper {
    background-color: white;

    /* https://css-tricks.com/the-trick-to-viewport-units-on-mobile/ */
    height: calc(var(--vh) * 100);
    left: 0;
    overflow-x: hidden;
    position: fixed;
    top: 0;
    transform: translateX(-100%) translateZ(0);
    transition: transform var(--menu-animation-duration);
    width: 345px;
    will-change: transform;
    z-index: 1000000;

    &_opened {
      transform: translateX(0) translateZ(0);
    }

    &_sidebar-opened .mobile-menu {
      transform: translateX(-100%) translateZ(0);
    }
  }

  &__submenu {
    left: 100%;
    list-style: none;
    padding: 70px 15px 15px 40px;
    position: absolute;
    top: 0;
    transition: visibility 0s var(--menu-animation-duration);
    visibility: hidden;
    width: 100%;
    will-change: visibility;

    &_opened {
      transition: visibility 0s;
      visibility: visible;
    }
  }

  &__link {
    display: block;
    line-height: 1.2;
    padding: 10px 0;
    user-select: none;

    &.promoted {
      padding-right: 2rem !important;

      &::after {
        float: right;
        margin-left: 6px;
        margin-top: 2px;

        @mixin new-label;
      }
    }
  }

  &__header {
    background-color: inherit;
    direction: rtl;
    display: flex;
    justify-content: space-between;
    left: 0;
    padding: 8px 8px 0 5px;
    position: sticky;
    top: 0;
    width: 100%;
    z-index: 1;

    .mobile-menu__wrapper_sidebar-opened & {
      direction: ltr;
    }
  }

  &__btn-back,
  &__btn-close {
    display: block;
    height: 40px;
    position: relative;
    width: 40px;

    &::before,
    &::after {
      background-color: var(--grey-600);
      border-radius: 2px;
      content: "";
      left: 50%;
      position: absolute;
      top: 50%;
      width: 3px;
    }
  }

  &__btn-back {
    display: none;

    .mobile-menu__wrapper_sidebar-opened & {
      display: block;
    }

    &::before,
    &::after {
      height: 11px;
    }

    &::before {
      transform: translate(-50%, -8px) rotate(45deg);
    }

    &::after {
      transform: translate(-50%, -2px) rotate(-45deg);
    }
  }

  &__btn-close {
    &::before,
    &::after {
      height: 20px;
    }

    &::before {
      transform: translate(-50%, -50%) rotate(45deg);
    }

    &::after {
      transform: translate(-50%, -50%) rotate(-45deg);
    }
  }
}

.bn-header-container {
  margin-left: auto;
  margin-right: auto;
  padding: 0 var(--spacing);
  width: 100%;

  @media screen and (min-width: 576px) {
    max-width: 540px;
  }

  @media screen and (min-width: 768px) {
    max-width: 720px;
  }

  @media screen and (min-width: 992px) {
    max-width: 960px;
  }

  @media screen and (min-width: 1200px) {
    max-width: 1140px;
  }
}

.bn-header {
  background-color: white;
  box-shadow: var(--box-shadow);
  font-weight: 500;

  &__inner {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    gap: 0 var(--spacing);
    justify-content: space-between;
    padding: 1rem 0;

    > form {
      flex-grow: 2;
    }

    .nav-link {
      color: inherit;
    }

    .nav-link:hover {
      color: var(--bn-orange);
    }

    @media (--screen-md-max) {
      > form {
        order: 3;
        width: 100%;
      }
    }

    @media screen and (min-width: 992px) {
      flex-wrap: nowrap;
    }

    @media (--screen-xlg-min) {
      margin-left: -144px;
      margin-right: -144px;
    }
  }

  .logo-main img {
    width: auto;

    @media (--screen-md) {
      max-height: 25px;
    }

    @media (--screen-md-min) and (--screen-xlg-max) {
      height: auto;
      width: 90px;
    }

    @media (min-width: 1439px) {
      max-height: 40px;
    }
  }

  .hamburger {
    background: transparent;
    border: 0;
    display: inline-block;
    margin-right: 12px;
    padding: 0;
    position: relative;
    vertical-align: top;

    span {
      background: var(--grey-600);
      border-radius: 1px;
      display: block;
      height: 3px;
      margin-bottom: 3px;
      position: relative;
      width: 18px;
    }
  }

  .js-nav-item-got-a-job-cta {
    @media screen and (--screen-lg-max) and (--screen-md-min) {
      display: none;
    }
  }

  nav {
    padding-left: 0;

    a:hover {
      text-decoration: none;
    }

    .nav-link {
      &.promoted {
        padding-right: 2rem !important;

        &::after {
          right: 9px;
          top: 5px;

          @mixin new-label;
        }

        &[aria-expanded="true"]::after {
          right: 6px;
        }
      }

      &::before {
        content: attr(data-title);
        display: block;
        font-weight: var(--font-bold);
        height: 0;
        overflow: hidden;
        visibility: hidden;
      }

      &::after {
        display: none;
      }
    }
  }

  .nav-item {
    .dp-item:active {
      background-color: white;
      color: var(--bn-dark-blue) !important;
    }

    .dp-item:focus {
      border: 1px solid var(--bn-orange);
    }
  }

  .top-right-menu {
    font-size: 0.875em;

    a:hover {
      text-decoration: none;
    }
  }

  /* Search bar. */
  form {
    #bn-site-search-box {
      background: var(--bn-grey-header-search) 0 0 no-repeat padding-box;
      border: 1px solid transparent;
      border-radius: 9px;
      opacity: 1;

      &:focus {
        background-color: var(--bn-grey-header-search);
        border: 1px solid var(--bn-orange);
        box-shadow: none;
      }

      &::placeholder {
        color: #b1b1b1;
      }
    }

    #bn-header-search {
      border-radius: 8px !important;
      margin: 1px 0 1px -51px;
      margin-left: -51px;
      transition: background 0.3s ease-out;
      width: 50px;
      z-index: 10;

      i {
        color: #050301;
        width: 100%;
      }
    }

    @media (--screen-md-min) {
      max-width: 500px;
    }

    @media (--screen-md-max) {
      margin-top: var(--spacing-xs);
    }
  }
}

@media (--screen-md-min) {
  .bn-header {
    #desktop-main-menu-container {
      background-color: white;
      font-size: var(--font-sm);
      justify-content: center;
    }

    &__inner {
      padding-bottom: var(--spacing);
      padding-top: var(--spacing);
    }
  }
}

.nav-user {
  flex-wrap: nowrap;
  font-weight: 500;

  & > *:last-child .nav-link {
    padding-right: 0;
  }

  &__icon {
    display: inline-block;
    fill: var(--bn-academy-orange);
    height: 16px;
    width: 16px;
  }

  .nav-link {
    &:focus,
    &:active {
      color: inherit;
    }

    @media (--screen-sm-max) {
      padding: 0 10px;
    }
  }
}

.nav-list {
  display: flex;
  flex-direction: row;
  list-style: none;
  text-decoration: none;
}

.staff-header {
  background-color: var(--bn-pistachio);
  box-shadow: 0 8px 6px -5px rgba(0, 0, 0, 30%);
}
