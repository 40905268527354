.bn-responsive-image-container {
  display: flex;
  height: 100%;
  width: 100%;
}

.bn-responsive-image {
  height: auto;
  object-fit: cover;
  object-position: top;
  width: 100%;
}
