@font-face {
  font-display: swap;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 200;
  src: url("https://fonts.gstatic.com/s/montserrat/v26/JTUSjIg1_i6t8kCHKm459WlhyyTh89Y.woff2")
    format("woff2");
}

@font-face {
  font-display: swap;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 400;
  src: url("https://fonts.gstatic.com/s/montserrat/v26/JTUSjIg1_i6t8kCHKm459WlhyyTh89Y.woff2")
    format("woff2");
}

@font-face {
  font-display: swap;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  src: url("https://fonts.gstatic.com/s/montserrat/v26/JTUSjIg1_i6t8kCHKm459WlhyyTh89Y.woff2")
    format("woff2");
}

@font-face {
  font-display: swap;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  src: url("https://fonts.gstatic.com/s/montserrat/v26/JTUSjIg1_i6t8kCHKm459WlhyyTh89Y.woff2")
    format("woff2");
}

@font-face {
  font-display: swap;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 700;
  src: url("https://fonts.gstatic.com/s/montserrat/v26/JTUSjIg1_i6t8kCHKm459WlhyyTh89Y.woff2")
    format("woff2");
}

@font-face {
  font-display: swap;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 800;
  src: url("https://fonts.gstatic.com/s/montserrat/v26/JTUSjIg1_i6t8kCHKm459WlhyyTh89Y.woff2")
    format("woff2");
}
